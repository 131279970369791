import React from 'react'
import Layout from '../Layout.js'
import Navbar from '../Navbar.js'
import SplashHeader from '../SplashHeader.js'
import {Link} from 'gatsby'

export default () => {
  return (
    <Layout>
      <SplashHeader image="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80">
        <div className="splash-text">
          <h1 className="display-5 mb-4">
            <strong>Who Are We?</strong>
          </h1>
          <p>
            We are a group of pragmatic, competent professionals who<br/>take our
            work seriously and leave our projects cleaner than we found them.
          </p>
          <p className="mb-5">
            We are platform and language agnostic, we learn quickly<br/>
            and we do our best not to drop the ball.
          </p>
          <Link className="btn btn-lg btn-callout" to="/hire">Give us a call</Link>
        </div>
      </SplashHeader>
    </Layout>
  )
}
